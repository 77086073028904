<template>
	<div style="padding-left: 0.5rem; " id="profile-info">
		<h4  style="font-size: 20px; font-weight:600; margin-bottom: 1.3rem;">
			Recruiters
		</h4>
		<b-card class=" mt-1 mb-0" v-for="(data, index) in recruitersData" :key="index">
		
			<b-row>
				<b-col cols="8">
					<div class="d-flex justify-content-start align-items-start">
						<div class="mr-1">
							<b-avatar size="40" style="border-radius: 16px !important;" :src="data.profilephoto"
								:text="avatarText(data.ContactName)" variant="transparent"
								class=" badge-minimal avatar-border text-secondary" />
						</div>
						<div class="profile-user-info">
							<h6 class="mb-25 font-weight-bold">
								{{ data.FirstName ? data.FirstName : data.ContactName }} {{ data.LastName }}
							</h6>
							<p class="mb-50">{{ data.Designation }}</p>
							<div class="d-flex justify-content-start align-items-center" v-if="data.email">
								<u-icon icon="fi-rr-envelope" class="mr-50 mt-25" size="14" />
								<span class="text-wrap">{{ data.email }}</span>
							</div>
							<div class="d-flex justify-content-start align-items-center" v-if="data.ContactNo">
								<u-icon icon="fi-rr-smartphone" class="mr-50 mt-25" size="14" />
								<span class="text-wrap">{{ data.ContactNo }}</span>
							</div>
							<!-- <p class="mb-0 d-flex" v-if="data.email">
								<u-icon icon="fi-rr-envelope" class="mr-50" size="10" />
								<p class="text-wrap">{{ data.email }}</p>
							</p> -->
							<!-- <p class="mb-0" v-if="data.ContactNo">
								<u-icon icon="fi-rr-smartphone" class="mr-50" size="10" />
								{{ data.ContactNo }}
							</p> -->
						</div>
					</div>
				</b-col>
				<b-col cols="4">
					<div class="d-flex profile-star ml-auto align-items-end justify-content-end">
						<b-badge v-if="data.isBlocked" pill variant="light-danger" class="text-capitalize">
							Blocked </b-badge>
						<b-badge v-else pill variant="light-success" class="text-capitalize">
							Active
						</b-badge>
					</div>
				</b-col>
			</b-row>
			<!-- <div class="d-flex justify-content-start align-items-start">
				<div class="mr-1">
					<b-avatar
						size="40"
						style="border-radius: 16px !important;"
						:src="data.profilephoto"
						:text="avatarText(data.ContactName)"
						variant="transparent"
						class=" badge-minimal avatar-border text-secondary"
					/>
				</div>
				<div class="profile-user-info">
					<h6 class="mb-25 font-weight-bold">
						{{ data.FirstName ? data.FirstName : data.ContactName }} {{ data.LastName }}
					</h6>
					<p class="mb-50">{{ data.Designation }}</p>
					<p class="mb-0 d-flex" v-if="data.email">
						<u-icon icon="fi-rr-envelope" class="mr-50" size="10" />
						{{ data.email }}
					</p>
					<p class="mb-0" v-if="data.ContactNo">
						<u-icon
							icon="fi-rr-smartphone"
							class="mr-50"
							size="10"
						/>
						{{ data.ContactNo }}
					</p>
				</div>
				<div class="profile-star ml-auto">
					<b-badge
						v-if="data.isBlocked"
						pill
						variant="light-danger"
						class="text-capitalize"
					>
						Blocked </b-badge
					><b-badge
						v-else
						pill
						variant="light-success"
						class="text-capitalize"
					>
						Active
					</b-badge>
				</div>
			</div> -->
		</b-card>
	</div>
</template>
<script>
import { BCard, BRow, BCol, BButton, BAvatar, BLink, BBadge,BCardBody } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
	components: {
		BCard,
		BAvatar, BBadge,BRow,BCol,BCardBody
	},
	props: {
		recruitersData: {
			type: Array,
		},
	},
	methods: {
		avatarText,
	},
};
</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
