<template>
	<div
		style="border-bottom: 1px solid #efefef; padding: 4px; margin-top: 0px !important;"
		class=" mt-0 mb-2 mr-50 "
	>
		<b-row class="match-height">
			<b-col xl="8" md="6">
				<b-media no-body style="align-items: center;">
					<b-media-aside class="mr-1" >
						<b-avatar
							class=" badge-minimal avatar-border-2 text-primary"
							size="120"
							:src="data.logo"
							:text="avatarText(data.CompanyName)"
							variant="transparent"
							style="border-radius: 52px !important;"
						/>
					</b-media-aside>
					<b-media-body >
						<h2 class=" font-weight-bold">
							{{ data.CompanyName }}
						</h2>
						<h4
							class="mt-50"
							style="font-size: 20px; font-weight:600"
						>
							{{ data.CompanyLocation }}
						</h4>
					</b-media-body>
				</b-media>
			</b-col>
			<b-col xl="4" md="6">
				<div class=" d-flex justify-content-md-end justify-content-sm-center align-items-center">
					<a :href="data.CompanyWebsite" target="blank"
							><span
								class="mt-50 "
								style="font-size: 16px;font-weight:600"
							>
								<u-icon
									icon="fi-rr-globe"
									class="mr-25"
									size="14"
								/>
								Visit Website</span
							></a
						>
				</div>
			</b-col>
		</b-row>
		<div v-if="data" style="margin-left: 134px; margin-top:16px" class="mb-2">
			<table class="mt-2 mt-xl-0 w-100">
				<tr>
					<td class="pb-50">
						<!-- <u-icon icon="fi-rr-venus-mars" class="mr-1" size="16" /> -->
						<span class="mr-50 font-weight-bold">Sector:</span>

						<span>{{ data.CompanySector }}</span>
					</td>
					<td class="pb-50">
						<!-- <u-icon icon="fi-rr-smartphone" class="mr-1" size="16" /> -->
						<span class="mr-50 font-weight-bold">Type:</span>
						<span>{{ data.CompanyType }}</span>
					</td>
				</tr>
				<tr>
					<td class="pb-50">
						<!-- <u-icon icon="fi-rr-cake-birthday" class="mr-1" size="16" /> -->
						<span class="mr-50 font-weight-bold">Size:</span>

						<span>{{ data.CompanySize }}</span>
					</td>
					<td class="pb-50 align-items-center justify-content-center">
						<!-- <u-icon icon="fi-rr-envelope" class="mr-1" size="16" /> -->
						<span class="mr-50 font-weight-bold">Age:</span>

						<span class="">{{ data.Age }}</span>
					</td>
				</tr>
			</table>

		</div>
	</div>
</template>
<script>
import {
	BRow,
	BCol,
	BAvatar,
	BMediaAside,
	BMediaBody,
	BMedia,
	BButton,
	BSpinner,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
	components: {
		BRow,
		BCol,
		BMediaBody,
		BMedia,
		BMediaAside,
		BAvatar,
		BButton,
		BSpinner,
	},
	props: {
		data: {
			type: Object,
		},
		exporting: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		avatarText,
	},
};
</script>
