<template>
	<div id="job-profile" class="mt-2">
			<b-row class="match-height">
				<b-col xl="8" :md="recruitersList.length > 0 ? 6 : 12" sm="12" style="border-right: 1px solid #efefef;" class="pr-2">
					<company-basic-profile
						:data="companyData"
					></company-basic-profile>
					<div
						v-if="companyData.CompanyDescription"
						class="text-justify pb-1 pl-50 mr-50"
					>
						<h4 style="font-size: 20px; font-weight:600;margin-bottom: 1.3rem;">
							About Company
						</h4>
						<span class="text-justify " style="font-size:16px">{{
							companyData.CompanyDescription
						}}</span>
					</div>
					<company-photos
						v-if="companyData.CompanyPhotos"
						:swiper-data="companyData.CompanyPhotos"
					></company-photos>
					<active-jobs :recruiter-list="recruitersList"></active-jobs>
				</b-col>
				<b-col xl="4" md="6" sm="12" v-if="recruitersList.length > 0">
					<recruiter-contact-list
						:recruiters-data="recruitersList"
					></recruiter-contact-list>
				</b-col>
			</b-row>
	</div>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import CompanyBasicProfile from "./components/CompanyBasicProfile.vue";
import CompanyPhotos from "./components/CompanyPhotos.vue";
import RecruiterContactList from "./components/RecruiterContactList.vue";
import ActiveJobs from "./components/CompanyActiveJobs.vue";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
	components: {
		BRow,
		BCol,
		CompanyBasicProfile,
		CompanyPhotos,
		RecruiterContactList,
		ActiveJobs
	},
	methods: {
		fetchCompanyData() {
			firebase
				.firestore()
				.collection("RecruiterCode")
				.doc(this.companyId)
				.get()
				.then((doc) => {
					// console.log(doc.data());
					this.companyData = doc.data();
				})
				.then(() => {
					if (this.collegeCode) {
						let recRef = firebase
							.firestore()
							.collection("RecruiterId").where("RecruiterCode","==" ,this.companyId)
							.where(
								"fromCollege",
								"array-contains",
								this.collegeCode
							);
						recRef.get().then((recruiters) => {
							recruiters.forEach((rec) => {
								let obj = rec.data();
								obj.id = rec.id;
								let blockedBy = rec.data().blockedBy ?? [];
								let deletedBy = rec.data().deletedBy ?? [];
								obj.isBlocked = blockedBy.includes(this.collegeCode);
								obj.isDeleted = deletedBy.includes(this.collegeCode);
								if (!obj.isDeleted) {
									console.log(obj);
									this.recruitersList.push(obj);
								}
							});
						});
					}
				});
		},
	},
	created() {
		this.companyId = this.$route.params.companyId;
		if (this.$store.state.mainUserCodes) {
			this.collegeCode = this.$store.state.mainUserCodes.CollegeCode;
			// console.log(this.collegeCode)
		}

		if (this.companyId) {
			this.fetchCompanyData();
		}
		this.$store.watch(
			(state) => {
				return this.$store.state.mainUserCodes;
			},
			(newVal, oldVal) => {
				this.collegeCode = this.$store.state.mainUserCodes.CollegeCode;
			},
			{ deep: true }
		);
	},
	computed: {},
	data() {
		return {
			companyId: "",
			companyData: {},
			collegeCode: null,
			recruitersList: [],
		};
	},
};
</script>
<style>
.job-profile-scroller {
	height: calc(100vh - (3.35rem + 4.45rem + 4rem));
	
}
@media (max-width: 768px) {
	.job-profile-scroller {
		height: auto
	}
}
@media (max-width: 991px) {
	.job-profile-scroller {
		height: auto
	}
}

</style>
