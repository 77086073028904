<template>
  <div class="mr-50 pl-50 my-1 ">
    <h4  style="font-size: 20px; font-weight:600;margin-bottom: 1.3rem;">
      Company Photos
    </h4>
    <swiper
      class="swiper-multiple match-height"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        class=""
        v-for="(data, index) in swiperData"
        :key="index"
      >
      <div class="company-photos ">
        <b-img :src="data" fluid />
      </div>
      </swiper-slide>

      <div slot="pagination" class="swiper-pagination" />
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  props: {
    swiperData: {
      type: Array,
    },
  },
  data() {
    return {
      /* eslint-disable global-require */

      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>
<style lang="scss">
.company-photos {
    max-width: -webkit-fill-available;
  height:100%;      
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #efefef;
    border-radius: 16px;
    background-color:#efefef;

  img {
    width: 300px;
        border-radius: 16px;

    height:170px;
    object-fit:cover
  }
}
</style>
